let config;

switch (process.env.REACT_APP_NODE_ENV) {
  case "production":
    config = require("./environments/dev.environment");
    break;
  case "test":
    config = require("./environments/dev.environment");
    break;
  case "development":
  default:
    config = require("./environments/dev.environment");
}

module.exports = config;