import { createSlice } from "@reduxjs/toolkit";
import {
  getEndpoints,
  getUser,
  getUserProfile,
  transferCashback,
} from "./UserAction";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    endpoints: [],
    profile: {
      _id: null,
      endpoint: {
        _id: null,
        url: null,
      },
      role: "investor",
      dataAllowance: 0,
      dataUsage: {
        usedSize: 0,
        percentage: 0,
        totalSize: 0,
      },
    },
    // currentViewedPost: null,
  },
  reducers: {
    setCurrentEndPoint: (state, action) => {
      state.profile = {
        ...state.profile,
        endpoint: state.endpoints.find((ep) => ep._id == action.payload),
      };
    },
  },
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(getUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data && action?.payload?.data.user) {
          state.profile = action?.payload?.data.user;
        }
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(transferCashback.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(transferCashback.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(transferCashback.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getEndpoints.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEndpoints.fulfilled, (state, action) => {
        state.loading = false;
        state.endpoints = action?.payload?.data;
        // if (state.endpoints?.length > 0) {
        //   state.currentEndPoint = state.endpoints[0];
        // }
      })
      .addCase(getEndpoints.rejected, (state, action) => {
        state.loading = false;
      });
    //   .addCase(getPost.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(getPost.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.currentViewedPost = action?.payload?.data;
    //   })
    //   .addCase(getPost.rejected, (state, action) => {
    //     state.loading = false;
    //   });
  },
});

export const { setCurrentEndPoint } = userSlice.actions;

export default userSlice.reducer;
