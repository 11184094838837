import { DocumentScanner } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import KeyboardBackspaceIcon from "@mui/icons-material/ArrowBack";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import HelpIcon from "@mui/icons-material/Help";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LogoutIcon from "@mui/icons-material/Logout";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Avatar,
  Button,
  capitalize,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import Logo from "../../assests/mainLogo.png";
// import Logo from "../../assests/logo/crop/white_logo_transparent.png";
// import Logo from "../../assests/logo/New-crop/white_transparent_logo.png";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useMediaQuery } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { PickersDay } from "@mui/x-date-pickers";
import Logo from "../../assests/logo/New-crop/color_logo.png";
import { formatBytes, formatSize } from "../../helpers/functions";
import { cookies } from "../../services/auth";
import {
  createReminder,
  getAllReminder,
} from "../../store/reminder/reminderActions";
import BreadcrumbsRoutes from "../BreadCrumpsRoutes";
import Notifications from "../Notifications";
import Reminders from "./Reminders";
import { getProfile } from "../../store/profile/profileActions";

const drawerWidth = 240;
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${0}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const stringAvatar = (firstName, lastName) => {
  if (firstName && lastName) {
    const initials = `${firstName[0]}${lastName[0]}`;
    return (
      <Avatar sx={{ bgcolor: "#24003D", color: "#fff" }}>{initials}</Avatar>
    );
  } else {
    return <Avatar>{/* Default Avatar content */}</Avatar>;
  }
};

const Home = ({ children }) => {
  console.log("children", children);
  const drawerRef = useRef(null);
  const [data, setData] = useState({
    topic: "",
    date: new Date(),
    notes: "",
    recurringReminder: false,
    frequency: "",
    endDate: new Date(),
  });
  const [notificationValue, setNotificationValue] = React.useState(0);
  const [toolopen, setToolOpen] = useState(false);
  const [calanderOpen, setCalanderOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);

  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [sideMenus, setSideMenus] = React.useState([]);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const dispatch = useDispatch();
  const location = useLocation();
  const profiles = useSelector((state) => state.profiles.profiles);
  const reminders = useSelector((state) => state.reminders.allReminders);
  const { SIDEBAR } = useSelector((state) => state.common.data);

  console.log("children", profiles, SIDEBAR);

  const renderIcon = (label) => {
    switch (label) {
      case "Dashboard":
        return <DashboardIcon sx={{ color: "white" }} />;
      case "Property":
        return <MapsHomeWorkIcon sx={{ color: "white" }} />;
      case "Portfolio":
        return <MapsHomeWorkIcon sx={{ color: "white" }} />;
      case "Documents":
        return <DocumentScanner sx={{ color: "white" }} />;
      default:
        return <AnalyticsIcon sx={{ color: "white" }} />;
    }
  };
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const getHeadersTitle = () => {
    const pathname = location.pathname;

    if (pathname === "/lvr-projections") {
      return "LVR Projections";
    }
    if (pathname === "/add-property") {
      return "Add Property";
    }
    if (pathname === "/add-document") {
      return "Add Document";
    }

    if (pathname.includes("edit-property") && propertyType) {
      return propertyType == "investmentProperty"
        ? "Edit Invesment Property"
        : `Edit ${capitalizeEachWord(propertyType)} Property`;
    } else {
      return getHeaderTitle(pathname);
    }
  };

  const getHeaderTitle = (key) => {
    switch (key) {
      case "/add-owner-occupied-property":
        return "Add Owner Occupied Property Details";
        break;
      case "/financial-position-assets-and-liabilities-statement":
        return "Financial Position - Assets & Liabilities Statement";
        break;
      case "/income-and-expense-breakdown-statement":
        return "Income & Expense Breakdown Statement";
        break;
      case "/profit-and-loss-summary":
        return "P&L Summary";
        break;
      case "/eofy Statement":
      case "/EOFY-statement":
        return "EOFY Statement";
        break;
      case "/rental-yield":
        return "Rental Yield";
        break;
      default:
        return capitalize(key.split("/")[1].split("-").join(" "));
        break;
    }
  };

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const capitalizeEachWord = (str) => {
    return str
      .split("-") // Split the string into words based on '-'
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join the words back together with space
  };

  const pathname = location.pathname;
  const capitalizedPathname = capitalizeEachWord(pathname);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dashboardPath = "/dashboard";

  const shouldShowBackButton = location.pathname !== dashboardPath;

  const goBack = () => {
    window.history.back();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    await cookies.remove("SID");
    window.location.reload(true);
    setTimeout(() => {
      navigate(profiles.role == "investor" ? `/login` : "/partner/login");
    }, 1000);
  };

  const handleLogout = () => {
    cookies.remove("SID");
    navigate(profiles.role == "investor" ? `/login` : "/partner/login");
    handleClose();
    handleDrawerToggle();
  };
  const propertyType = useSelector((state) =>
    state.properties.property._id
      ? state.properties.property.property.type
      : null
  );

  useEffect(() => {
    setOpen(true);
  }, [!isMobileScreen]);
  useEffect(() => {
    if (profiles._id != null) {
      let menus = SIDEBAR.find((s) => s.role == profiles.role).menus;
      setSideMenus(menus);
    }
  }, [SIDEBAR, profiles]);

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={() => setMobileMoreAnchorEl(null)}
    >
      <MenuItem>
        <IconButton size="large" color="inherit">
          <Badge color="error">
            <SettingsOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Setting</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 5 new notifications"
          color="inherit"
        >
          <Badge badgeContent={5} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={(event) => setAnchorEl(event.currentTarget)}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const list = (anchor) => (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        width: anchor === "top" || anchor === "bottom" ? "auto" : 380,
      }}
      role="presentation"
    >
      <Reminders anchor={anchor} toggleDrawer={toggleDrawer} />
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{
          boxShadow: "unset",
        }}
        className="background"
        color="inherit"
        position="fixed"
        open={open}
      >
        <Toolbar>
          <Box></Box>
          <Box width={"100%"}>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ display: { md: "none", xs: "flex" }, color: "#000" }}
            >
              <MenuIcon />
            </IconButton>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              sx={{ display: { md: "flex", xs: "none" } }}
            >
              {shouldShowBackButton && (
                <IconButton onClick={() => goBack()}>
                  <KeyboardBackspaceIcon
                    sx={{
                      color: "#24003D",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
              <Stack>
                <Typography
                  sx={{
                    fontWeight: 500,

                    fontSize: "25px",
                  }}
                >
                  <> {getHeadersTitle(capitalizedPathname)}</>
                </Typography>
                <BreadcrumbsRoutes />
              </Stack>
            </Stack>
          </Box>

          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Tooltip
                  title="Calendar"
                  open={calanderOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <CalendarTodayIcon
                    onMouseEnter={() => setCalanderOpen(true)}
                    onMouseLeave={() => setCalanderOpen(false)}
                    onClick={toggleDrawer(anchor, true)}
                    sx={{ fontSize: 18, cursor: "pointer" }}
                  />
                </Tooltip>

                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  ref={drawerRef}
                  sx={{
                    "& .MuiDrawer-paper": {
                      borderTopLeftRadius: "20px",
                    },
                  }}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
            <Notifications />
            <Tooltip
              title="Quick Help"
              open={helpOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <LightbulbIcon
                onClick={() => {
                  navigate("/help", { state: { faq: false } });
                }}
                onMouseEnter={() => setHelpOpen(true)}
                onMouseLeave={() => setHelpOpen(false)}
                sx={{ fontSize: 20, cursor: "pointer" }}
              />
            </Tooltip>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Box>
              <div>
                <Stack direction={"row"} alignItems={"center"}>
                  <Tooltip
                    title="User Profile"
                    open={toolopen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onMouseEnter={() => setToolOpen(true)}
                      onMouseLeave={() => setToolOpen(false)}
                      onClick={handleMenu}
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                          opacity: 1,
                        },
                      }}
                      color="inherit"
                    >
                      <Stack
                        style={{
                          border: "3px solid #24003D",
                          // display: "inline-block",
                          borderRadius: "5px",
                          padding: 2,
                          textTransform: "capitalize",
                          maxWidth: "200px",
                          // minWidth:"100%",
                        }}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <div>
                          {profiles &&
                          profiles?.firstName &&
                          profiles?.lastName ? (
                            stringAvatar(
                              capitalize(profiles.firstName),
                              capitalize(profiles.lastName)
                            )
                          ) : (
                            <span>N/A</span>
                          )}
                        </div>
                        <Stack
                          style={{ overflow: "hidden" }}
                          direction={"row"}
                          spacing={1}
                        >
                          <Typography
                            sx={{
                              cursor: "pointer",
                              fontWeight: 600,
                              color: "#0f2e5a",
                              fontSize: "19px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: "100%",
                            }}
                          >
                            {profiles.firstName} {profiles.lastName}
                          </Typography>
                        </Stack>
                      </Stack>
                    </IconButton>
                  </Tooltip>
                </Stack>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/settings");
                      handleClose();
                      handleDrawerToggle();
                    }}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </Box>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={(event) => setMobileMoreAnchorEl(event.currentTarget)}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          height: "100vh",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        // variant="persistent"
        variant={window.innerWidth < 600 ? "temporary" : "persistent"}
        anchor="left"
        open={window.innerWidth < 600 ? open : true}
        className="background"
      >
        <DrawerHeader
          className="background"
          sx={{
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack
            spacing={1}
            mt={"10px"}
            mb={"10px"}
            direction={"row"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
              setOpen(false);
            }}
          >
            <img src={Logo} alt="logo" height={"auto"} width={210} />
            {/* <Typography sx={{ color: "#fff", fontWeight: 600, fontSize: 20 }}>
              InvestPulse
            </Typography> */}
          </Stack>
          {window.innerWidth < 600 && (
            <IconButton onClick={handleDrawerToggle} sx={{ color: "#000" }}>
              {/* <MenuIcon /> */}
              <CloseIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Stack
          className="background"
          sx={{
            height: "100vh",
            justifyContent: "space-between",
            bgcolor: "#24003D",
          }}
        >
          <List className="sidebar-button">
            <ListItem disablePadding sx={{ display: "block" }}>
              <Stack>
                {/* <ListItemButton
                  onClick={() => {
                    navigate("/dashboard");
                    if (window.innerWidth < 600) {
                      setOpen(false);
                    }
                  }}
                  sx={{
                    minHeight: 48,
                    // minHeight: open ? 48 : 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DashboardIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Dashboard
                  </Typography>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate("/property");
                    if (window.innerWidth < 600) {
                      setOpen(false);
                    }
                  }}
                  sx={{
                    minHeight: 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                    // gap:1
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MapsHomeWorkIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Property
                  </Typography>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate("/reports");
                    if (window.innerWidth < 600) {
                      setOpen(false);
                    }
                  }}
                  sx={{
                    minHeight: 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                    // gap:1
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AnalyticsIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Reports
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    navigate("/cashflow");
                    if (window.innerWidth < 600) {
                      setOpen(false);
                    }
                  }}
                  sx={{
                    minHeight: 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                    // gap:1
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AnalyticsIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Cashflow
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    navigate("/myListings");
                    if (window.innerWidth < 600) {
                      setOpen(false);
                    }
                  }}
                  sx={{
                    minHeight: 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                    // gap:1
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AnalyticsIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Campaign
                  </Typography>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate("/settings");
                    if (window.innerWidth < 600) {
                      setOpen(false);
                    }
                  }}
                  sx={{
                    minHeight: 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                    // gap:1
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AnalyticsIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Partner Settings
                  </Typography>
                </ListItemButton> */}

                {sideMenus.map((menu) => {
                  return (
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${menu.url}`);
                        if (window.innerWidth < 600) {
                          setOpen(false);
                        }
                      }}
                      sx={{
                        minHeight: 48,
                        marginTop: 1,
                        justifyContent: open ? "initial" : "initial",
                        px: 4,
                        py: 1.3,
                        // gap:1
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: open ? 0 : 0,
                          mr: open ? 1 : 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {renderIcon(menu.label)}
                      </ListItemIcon>
                      <Typography
                        sx={{
                          opacity: open ? 1 : 1,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        {menu.label}
                      </Typography>
                    </ListItemButton>
                  );
                })}
              </Stack>
            </ListItem>
          </List>
          <List className="sidebar-button">
            <ListItem disablePadding sx={{ display: "block" }}>
              <Stack>
                <ListItemButton
                  onClick={() => {
                    navigate("/help", { state: { faq: true } });
                    setOpen(false);
                  }}
                  sx={{
                    minHeight: 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                    // gap:1
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <HelpIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Help
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                    // gap:1
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DonutSmallIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    Data Usage - {profiles?.dataUsage?.percentage}%
                  </Typography>
                </ListItemButton>
                <Stack spacing={1} alignItems={"center"}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={profiles?.dataUsage?.percentage}
                    style={{
                      width: "70%",
                    }}
                  />
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontWeight: 600,
                      color: "#fff",
                      fontSize: "12px",
                      textAlign: "start",
                    }}
                  >
                    <span>
                      {profiles?.dataUsage?.usedSize &&
                        formatSize(profiles?.dataUsage?.usedSize)}{" "}
                      of{" "}
                      {profiles?.dataUsage?.totalSize &&
                        formatBytes(profiles?.dataUsage?.totalSize)}
                    </span>
                  </Typography>
                </Stack>
                <ListItemButton
                  onClick={() => {
                    navigate("/settings");
                    setOpen(false);
                  }}
                  sx={{
                    minHeight: 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                    // gap:1
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SettingsApplicationsIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Settings
                  </Typography>
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    cookies.remove("SID");
                    onLogout();
                    setOpen(false);
                  }}
                  sx={{
                    minHeight: 48,
                    marginTop: 1,
                    justifyContent: open ? "initial" : "initial",
                    px: 4,
                    py: 1.3,
                    // gap:1
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: open ? 0 : 0,
                      mr: open ? 1 : 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LogoutIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      opacity: open ? 1 : 1,
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      cookies.remove("SID");
                      navigate("/login");
                    }}
                  >
                    Logout
                  </Typography>
                </ListItemButton>
              </Stack>
            </ListItem>
          </List>
        </Stack>
      </Drawer>

      <Main
        sx={{
          backgroundColor: "#f6f6f6",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
        open={open}
      >
        {children}
      </Main>
      {renderMobileMenu}
    </Box>
  );
};

export default Home;
