import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildQueryParams } from "../../helpers/functions";
import apiRequest from "../../services/auth";
import { getProfile } from "../profile/profileActions";

export const createCampaign = createAsyncThunk(
  "campaign/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/campaign/create",
      method: "POST",
      data,
    }).then(async (response) => {
      return response;
    });
    return res;
  }
);

export const getAllCampaign = createAsyncThunk(
  "campaign",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/campaign`,
      method: "GET",
      // data,
    }).then((response) => {
      console.log("responseresponse", response);
      return response;
    });
    return res;
  }
);

export const getCampaign = createAsyncThunk(
  "campaign/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/campaign/get?campaignId=${data}`,
      method: "GET",
      // data,
    }).then((response) => {
      console.log("oneeeeeeeeeeee", response);
      return response;
    });
    return res;
  }
);
