import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import {
  createCampaign,
  getAllCampaign,
  getCampaign
 
} from "./campaignActions";

const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    loading: false,
    campaigns: [],
    campaign: {},
   
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(createCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.campaign = action?.payload?.data;
        }
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllCampaign.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.campaigns = action?.payload?.data;
        }
      })
      .addCase(getAllCampaign.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.campaign = action?.payload?.data;
        }
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.loading = false;
      })

  },
});

export default campaignSlice.reducer;
